import React from 'react';
import { graphql } from 'gatsby'
import SEO from "../components/seo"
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Breadcrumb from "../components/AreaGuideDetails/Breadcrumbs/AreaGuideDetailsBreadcrumbs"
import Banner from '../components/AreaGuideDetails/AreaGuideBanner/AreaGuideBanner';
import Details from "../components/AreaGuides/DetailsContent"
import Help from "../components/Home/FormBlock/FormBlock"
import Properties from "../components/PropertyDetails/Property/Property"
import LocRatingMap from '../components/map/loc-rating-map-area-guides';

import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss";
import "../scss/custom.scss";

const AreaGuideTemplate = (props) => {

  const GQLPage = props.data?.glstrapi?.areaGuide;
  const GlobalModule = props.data?.glstrapi?.globalModule?.Area_Guide_Details_Footer_Help;
  return (
    <React.Fragment>
      <div className="wrapper area-guide-wrapper area-guide-details-template">
        <SEO title={GQLPage.Meta_Title} description={GQLPage.Meta_Description ? GQLPage.Meta_Description : `Get to know local areas in ${GQLPage.Name} and contact one of our estate agents for assistance in finding the right property for you.`} image={GQLPage.Banner_Image ? GQLPage.Banner_Image.url : ''} />
        <Header />
        <Breadcrumb Pagename={GQLPage.Name} />
        {GQLPage.Banner_Image &&
          <Banner {...GQLPage} />
        }

        <Details {...GQLPage} />
        <Properties  {...GQLPage} area={GQLPage.Name} />
        {GlobalModule &&
        <Help Content={GlobalModule.Content} Show_Chart={GlobalModule.Show_Chart} CTA_1_Label={GlobalModule.CTA_1_Label} CTA_1_Link={GlobalModule.CTA_1_Link} CTA_2_Label={GlobalModule.CTA_2_Label} CTA_2_Link={GlobalModule.CTA_2_Link} /> }
        <Footer search="area_office" url={GQLPage.URL} area={GQLPage.Name} />
      </div>
    </React.Fragment>
  )
}
export default AreaGuideTemplate;



export const pageQuery = graphql`
  query areaQuery($slug: ID!) {
      glstrapi {
        areaGuide(id: $slug) {
            id
            Banner_Image {
              url
              alternativeText
            }
            Banner_Content
            Add_Content {
              ... on GLSTRAPI_ComponentModulesContent {
                id
                Add_Content
              }
              ... on GLSTRAPI_ComponentModulesImageOrVideo {
                id
                Video_URL
                Image {
                  url
                  alternativeText
                }
              }
            }
            Longtitude
            Latitude
            Meta_Description
            Meta_Title
            Name
            URL
            Speak_With {
              Name
              Designation
              Phone
              Image {
                alternativeText
                url
              }
            }
            imagetransforms
        }

        globalModule {
          Area_Guide_Details_Footer_Help {
            CTA_1_Label
            CTA_2_Label
            Content
            Name
            Show_Chart
            Select_Form {
              Select
            }
            CTA_2_Link {
              id
            }
            CTA_1_Link {
              id
            }
          }
        }

      }
  }
`